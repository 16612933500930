<template>
    <div class="interfaceUser-table">
        <div class="table-con">
            <div class="handle-btn">
                <el-button type="primary" size="small" @click="handleAdd">
                    新增
                </el-button>
                <el-button type="danger" size="small" @click="multiDel">
                    批量删除
                </el-button>
            </div>
            <el-table
                :data="tableData"
                stripe
                style="width: 100%"
                height="calc(100% - 75px)"
                @selection-change="handleSelectionChange"
            >
                >
                <el-table-column
                    type="selection"
                    width="55"
                >
                </el-table-column>
                <el-table-column
                    label="用户名"
                >
                    <template slot-scope="scope">
                        {{ scope.row.login_name }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="中文名"
                >
                    <template slot-scope="scope">
                        {{ scope.row.user_name }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="电话"
                >
                    <template slot-scope="scope">
                        {{ scope.row.phone }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="IP范围"
                >
                    <template slot-scope="scope">
                        {{ scope.row.ip_range }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="描述"
                >
                    <template slot-scope="scope">
                        {{ scope.row.remarks }}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="editRow(scope.$index, scope.row)"
                        >
                            编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="table-page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pagetion.pageindex"
                    :page-sizes="pagetion.pagesizes"
                    :page-size="pagetion.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagetion.total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            tableData: [],
            pagetion: {
                pageindex: 1,
                pagesize: 20,
                total: 0,
                pagesizes: [10, 20, 30, 40, 50, 100],
            },
            selectData: [], // 多选选中数据集合
        };
    },
    watch: {
    },
    computed: {},
    methods: {
        // 获取用户分页数据
        getUsers() {
            this.$axios
                .get('/interfaceApi/datacenter/interface/user/getpage'
                + '?pageindex=' + this.pagetion.pageindex + '&pagesize=' + this.pagetion.pagesize)
                .then(res => {
                    if (res) {
                        this.pagetion.total = res.total;
                        this.tableData = res.rows;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 切换页码
        handleCurrentChange(page) {
            this.pagetion.pageindex = page;
            this.getUsers();
        },
        // 切换每页条数
        handleSizeChange(size) {
            this.pagetion.pagesize = size;
            this.getUsers();
        },
        // 多选事件
        handleSelectionChange(val) {
            this.selectData = val;
        },
        // 新增
        handleAdd() {
            this.$toast(
                {
                    title: true,
                    text: '新增用户',
                    type: 'eject',
                    width: '9rem',
                    height: '70%',
                    t_url: 'dataBaseCenter/dataCollection/interfaceUser/addPop',
                    viewPosition: 'view',
                    extr: { closeBtn: {}, closeCall: this.addFinshed },
                });
        },
        // 添加完成回调
        addFinshed() {
            window.hideToast();
            this.getUsers();
        },
        // 编辑数据
        editRow(index, rowData) {
            this.$toast(
                {
                    title: true,
                    text: '编辑用户',
                    type: 'eject',
                    width: '9rem',
                    height: '70%',
                    t_url: 'dataBaseCenter/dataCollection/interfaceUser/addPop',
                    viewPosition: 'view',
                    extr: { closeBtn: {}, rowData: rowData, closeCall: this.addFinshed },
                });
        },
        // 批量删除
        multiDel() {
            if (this.selectData.length < 1) {
                this.$message.warning('请先勾选将要保存的数据');
                return;
            }
            let ids = '';
            this.selectData.map(item => {
                ids += item.id + ',';
            });
            ids = ids.substring(0, ids.length - 1);
            this.$axios
                .delete('/interfaceApi/datacenter/interface/user/' + ids)
                .then(res => {
                    if (res) {
                        this.$message.success('删除成功');
                        this.getUsers();
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    created() {
        this.getUsers();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.interfaceUser-table
    padding .15rem
    height 100%
    .table-con
        height 100%
        background #fff
        padding .15rem
        .handle-btn
            text-align right
            margin-bottom .1rem
        .el-table
            border 1px solid #f0f0f0
            border-radius 2px
            thead
                color #022782
                background #EDF0F5
                th
                    padding .06rem 0
                    background #EDF0F5
                    text-align center
            tr
                td
                    padding .06rem 0
                    border-bottom 0
                    text-align center
                &.el-table__row--striped
                        background #f8fafd
        .table-page
            text-align right
            margin-top .1rem
</style>